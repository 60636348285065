import React from "react"
import { useIntl } from "gatsby-plugin-intl"
import Typography from '@material-ui/core/Typography'
import { graphql, useStaticQuery } from 'gatsby'
import BackgroundImage from 'gatsby-background-image'
import { makeStyles } from '@material-ui/styles'
import SEO from '../components/seo'
import { MESSAGE } from '../intl/default-messages'

const useStyles = makeStyles({
  wrapper: {
    height: '100vh',
    width: '100vw',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  headerWrapper: {
    background: 'rgba(0,0,0,0.6)',
    padding: '25px',
    borderRadius: '50px',
    textAlign: 'center',
  }
})

const Home = () => {
  const classes = useStyles();
  const intl = useIntl();

  const data = useStaticQuery(
    graphql`
      query {
        code: file(relativePath: { eq: "mountain.jpg" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `
  );

  return (
    <BackgroundImage
      Tag="section"
      className={classes.wrapper}
      fluid={data.code.childImageSharp.fluid}
      backgroundColor={`#040e18`}
    >
      <SEO title={intl.formatMessage(MESSAGE.home)}/>
      <div className={classes.headerWrapper}>
        <Typography variant='h1'>Tomas Sjösten</Typography>
      </div>
    </BackgroundImage>
  )
}

export default Home;
