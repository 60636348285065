import { defineMessages } from "gatsby-plugin-intl"


export const MESSAGE = defineMessages({
  logout: {
    id: 'logout',
    defaultMessage: 'Logout',
  },
  login: {
    id: 'login',
    defaultMessage: 'Login',
  },
  home: {
    id: 'home',
    defaultMessage: 'Home',
  },
})
